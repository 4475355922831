<template>
  <v-dialog
    v-model="isShow"
    max-width="800"
    @click:outside="$emit('cancel')"
  >
    <v-card>
      <v-card-title>プライバシーポリシー</v-card-title>
      <v-card-text class="privacy">
        <article>
          <p>株式会社ニューメディア（以下「当社」といいます）は、お客様により良いサービスを提供するために、個人情報の適正な収集、利用の基準と運用についての具体的なルールを定め、お客様の個人情報を大切な財産として適切に取り扱います。当社は、個人情報の保護に関する法律（以下「個人情報保護法」といいます）およびその他の関連法令を遵守し、個人情報保護の責任者を決めルールに則り適正に取り扱われているかチェックを行い、発見された問題については常に改善していきます。当社は、個人情報の正確性を保ち、個人情報が社外に漏れたり不当に改ざんされたりするなどのトラブルによってお客様にご迷惑をおかけしないよう、技術的・人的な安全対策を整備します。</p>
          <h2>◆個人情報の取り扱いについて</h2>
          <section>
            <h3>個人情報及び利用者情報の取得</h3>
            <p>当社は、当社の提供するサービス(以下「本サービス」といいます)において、氏名、住所、生年月日、電話番号、電子メールアドレス、金融機関口座情報等の個人情報（個人情報保護法第2条第1項により定義される個人情報に該当するもの）並びに次に定める同意情報及び収集情報（以下、同意情報及び収集情報を併せて「利用者情報」といいます）を必要に応じて取得します。</p>
            <h4>【同意情報】</h4>
            <p>本サービスのご利用にあたり、外部サービスとの連携を許可した場合にお客様が許可した内容に基づき当社が収集する次の情報。
              <ul>
                <li>外部サービスでお客様が利用するID等</li>
                <li>外部サービスのプライバシー設定によりお客様が連携先に開示を認めた情報</li>
              </ul>
            </p>
            <h4>【収集情報】</h4>
            <p>お客様の本サービスへのアクセス状況やそのご利用方法に関する次の情報
              <ul>
                <li>端末の個体識別情報</li>
                <li>通信履歴</li>
                <li>ご利用いただいたサービスやご覧になったページや広告の履歴等</li>
                <li>クッキー情報</li>
                <li>位置情報</li>
              </ul>
            </p>
          </section>
          <section>
            <h3>利用目的</h3>
            <p>当社は、お客様から取得する個人情報及び利用者情報の利用目的を特定し明らかにし、その目的以外で個人情報及び利用者情報を利用しません。当社がお客様から個人情報及び利用者情報を取得する場合には、以下の目的のために利用させていただきます。
              <ul>
                <li>本サービスの提供・運営のため</li>
                <li>ご質問の受付・調査・回答のため</li>
                <li>本サービスに関連する、新機能、更新情報、キャンペーン等の案内のメールを送付するため</li>
                <li>ご注文商品・プレゼントなどの賞品等の発送、返品等のため</li>
                <li>配送時の確認連絡</li>
                <li>ご注文受付・お支払等の処理のため</li>
                <li>メンテナンス、重要なお知らせなど必要に応じたご案内のため</li>
                <li>個人情報及び利用者情報の属性の集計、分析、統計資料作成のため(統計資料とは、個人が識別・特定できないように加工したものをいい、新規サービスの開発等の業務の遂行のために利用、処理することがあります。また、統計資料を業務提携先に提供することがあります。)</li>
                <li>マーケティング調査・プランニング及び研究開発のため</li>
                <li>利用規約に違反した方の本人特定をし、ご利用をお断りするため</li>
                <li>お客様がサービス上で目にする広告やコンテンツをそれぞれのお客様向けにカスタマイズするなど（第三者のサービス上で目にする本サービスに関する広告やコンテンツをそれぞれのお客様向けにカスタマイズして表示することを含みます。）、利便性を向上するため</li>
                <li>お客様にご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため</li>
                <li>有料サービスにおいて、お客様にご利用料金を請求するため</li>
                <li>当社の提供するサービスにお客様がアップロードしたコンテンツを利用することについて、アップロードしたお客様にご連絡するため</li>
                <li>広告事業におけるスポンサー企業及び業務提携企業のサービスに関する情報をご案内するため(業務提携企業とは、共同でサービスを提供する提携企業先、当社と共同研究を行う企業、グループ会社をいいます。個人情報は、以下の３．から５．までの場合を除き、業務提携企業に提供するものではなく、また利用者情報及び以下の３．から５により個人情報を提供する場合であっても、当社は業務提携企業に対し必要かつ適切な監督を行います。)</li>
                <li>業務提携企業がある場合で、かつ当社と共同で提供するサービス(以下「共同サービス」といいます)をお客様が利用される場合に、業務提携企業に対して、共同サービスの提供に必要な最小限の項目の利用者情報を提供するため</li>
                <li>業務提携企業に収集情報（識別できるお客様のID等は含みません。）を提供し、サービス向上・技術改善を目的とした共同研究を行うため（その研究結果を公表することがあります。）</li>
              </ul>
            </p>
          </section>
          <section>
            <h3>個人情報の第三者提供</h3>
            <p>当社は、原則としてお客様の同意を得ることなく第三者に個人情報を開示・提供することはありませんが、以下の場合には開示・提供することができるものとします。
              <ul>
                <li>法令に基づく場合</li>
                <li>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合</li>
                <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
                <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
                <li>国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要があって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
                <li>事業者の提供するサービスの利用規約で利用者が同意している場合に該当するとき</li>
              </ul>
            </p>
          </section>
          <section>
            <h3>業務委託</h3>
            <p>当社は、サービス・商品を提供するために必要な範囲で個人情報取扱いを含む業務を委託先に業務委託することがあります。その場合にも、委託先が漏洩や当社の同意なく再提供を行わないよう契約により義務付け、適切に管理します。なお、サービス・商品を提供するためやむを得ない場合には、業務委託先が直接お客様に連絡することがあります。予めご了承ください。</p>
          </section>
          <section>
            <h3>免責</h3>
            <p>個人情報の漏洩等がないよう当社は法律その他の規程に基づき適切にサービス運営を行いますが、以下の場合に、第三者が個人情報を取得したことにより発生したトラブルについては免責されるものとします。
              <ul>
                <li>お客様自らが本サービス上の機能又は別の手段を用いて他のお客様に個人情報を明らかにする場合</li>
                <li>他のお客様がサービス上に入力した情報により、期せずして本人が特定できてしまった場合</li>
                <li>その他当社に過失がないにも拘わらず発生した漏洩等の事故</li>
              </ul>
            </p>
          </section>
          <section>
            <h3>個人情報の開示</h3>
            <p>当社は、お客様から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、お客様ご本人からのご請求であることを確認の上で、お客様に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。</p>
          </section>
          <section>
            <h3>個人情報の訂正等</h3>
            <p>当社は、お客様から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます）を求められた場合には、お客様ご本人からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨をお客様に通知します（訂正等を行わない旨の決定をしたときは、お客様に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合は、この限りではありません。</p>
          </section>
          <section>
            <h3>個人情報の利用停止等</h3>
            <p>当社は、お客様から、お客様の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます）を求められた場合において、そのご請求に理由があることが判明した場合には、お客様ご本人からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等を行い、その旨をお客様に通知します。但し、個人情報保護法その他の法令により、当社が利用停止等の義務を負わない場合は、この限りではありません。</p>
          </section>
          <section>
            <h3>お問い合わせ窓口及び個人情報の開示等請求手続き</h3>
            <h4>【お問い合わせ窓口】</h4>
            <p>お客様ご本人の個人情報のご意見、ご質問、苦情のお問い合わせについては、当WEBサイトの「お問合せ」ページにて受け付けております。</p>
            <p>当社は、お客様より個人情報開示のご請求の連絡を受領した後、お客様がご請求される内容に応じて、ユーザーID、ご登録のメールアドレス等のご提示をお客様に求め、お客様ご本人からのご請求であることを確認させていただきます。当該確認の完了をもちまして、当社は個人情報保護方針の定めに従い遅滞なくご対応申し上げます。</p>
            <p>なお、ご請求の対象が氏名、住所、電話番号、金融機関口座情報、クレジットカード情報等の重要な個人情報に関する場合、又は、ご請求がご本人以外からの代理のご請求の場合には、当社が指定するご本人確認に必要な書面、又は、代理権を有することを証する書面をご提出いただきます。予めご了承ください。</p>
          </section>
          <section>
            <h3>その他</h3>
            <p>お客様ご自身の情報を最新かつ正確にご提供いただけない場合には、お客様は適正なサービスが受けられないことがありますのでご注意ください。</p>
          </section>
        </article>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'privacy',
  props:{
    isShow: Boolean
  }
}
</script>

<style scoped>
.privacy article{
  counter-reset: number 0;
}
.privacy h3:before{
  counter-increment:number 1;
  content: counter(number) ".";
}
</style>
