<template>
  <v-footer 
    padless
    :class="{mobile: isMobile}"
  >
    <modal-footer-terms 
      :isShow="isTermsShow"
      @cancel="isTermsShow = false"
    />
    <modal-footer-regulation 
      :isShow="isRegulationShow" 
      @cancel="isRegulationShow = false"
    />
    <modal-footer-privacy 
      :isShow="isPrivacyShow" 
      @cancel="isPrivacyShow = false"
    />
    <v-btn
      text class="my-2 text-caption"
      @click="isTermsShow = true"
    >
      利用規約
    </v-btn>
    <v-btn
      text class="my-2 text-caption"
      @click="isRegulationShow = true"
    >
      商取引法に基づく表示
    </v-btn>
    <v-btn
      text class="my-2 text-caption"
      @click="isPrivacyShow = true"
    >
      プライバシーポリシー
    </v-btn>
    <v-spacer />
    <v-sheet
      width="60"
      height="60"
      class="my-auto"
    >
      <v-img
        src="@/assets/images/jasrac.jpg"
      />
    </v-sheet>
    <p class="text-caption pt-2 mr-2">JASRAC許諾番号<br/>9025221001Y45037</p>
    <v-sheet
      width="60"
      height="60"
      class="my-auto"
    >
      <v-img
        src="@/assets/images/nextone.png"
      />
    </v-sheet>
    <p class="text-caption pt-2 mr-6">NexTone許諾番号<br/>ID000006279</p>
    <p class="pt-4">(c){{ new Date().getFullYear() }} - <strong>NewMedia</strong></p>
  </v-footer>
</template>

<script>
import ModalFooterTerms from '@/components/molecules/ModalFooterTerms'
import ModalFooterRegulation from '@/components/molecules/ModalFooterRegulation'
import ModalFooterPrivacy from '@/components/molecules/ModalFooterPrivacy'

export default {
  name: 'StoneFooter',
  components:{
    ModalFooterTerms,
    ModalFooterRegulation,
    ModalFooterPrivacy
  },
  props:{
    isMobile: Boolean
  },
  data(){
    return{
      isTermsShow: false,
      isRegulationShow: false,
      isPrivacyShow: false
    }
  }
}
</script>
<style scoped>
.v-footer .v-sheet{
  margin-right: 20px; 
  margin-left: 20px;
}
@media screen and (max-width: 800px) {
  .v-footer>*{
    width: 100%;
    text-align: center;
  }
  .v-footer .v-sheet{
    margin-right: auto; 
    margin-left: auto;
  }
}
.mobile{
  padding-bottom: 48px;
}
</style>
