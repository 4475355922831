<template>
  <v-dialog
    v-model="isShow"
    max-width="800"
    @click:outside="$emit('cancel')"
  >
    <v-card>
      <v-card-title>特定商取引法に基づく表記</v-card-title>
      <v-card-text class="regulation">
        <article>
          <dl>
            <dt>サービス内容</dt>
            <dd>映像の配信権利を購入し、視聴するサービスです。</dd>
            <dt>サービス提供方法</dt>
            <dd>インターネット通信により随時提供されます。</dd>
            <dt>サービス対価及び支払方法</dt>
            <dd>サービス表示画面において説明されます。</dd>
            <dt>サービスの対価以外にお客様に発生する費用</dt>
            <dd>サービスを提供しているサイトにアクセスする際に発生するPCや携帯電話でインターネットをするために必要となる通信料、プロバイダ料金、その他インターネット利用のために必要となる料金</dd>
            <dt>サービス提供期間</dt>
            <dd>サービス表示画面において説明されます。</dd>
            <dt>サービス提供開始後のキャンセル</dt>
            <dd>サービスの性質上、サービス提供開始後のお客様都合によるキャンセルはお受けしておりません。</dd>
            <dt>サービス提供事業者</dt>
            <dd>株式会社ニューメディア</dd>
            <dt>会社所在地</dt>
            <dd>〒992-0044&nbsp;山形県米沢市春日四丁目2-75</dd>
            <dt>代表者名</dt>
            <dd>金子&nbsp;敦</dd>
            <dt>サービスに関する問い合わせ先</dt>
            <dd>当サイト内に問い合わせページを設置しております。</dd>
            <dt>連絡先</dt>
            <dd>〒992-0044&nbsp;山形県米沢市春日四丁目2-75<br>TEL:0238-24-2525<br>※サービスに関するお問い合わせは電話では受け付けておりません。</dd>
            <dt>動作環境</dt>
            <dd>動作環境に関するお問い合わせは当サイト内「サービス利用について」をご利用ください。</dd>
            <dt>注意事項</dt>
            <dd>特定商取引法に規定されるクーリング・オフが適用されるサービスではありません。</dd>
          </dl>
        </article>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'regulation',
  props:{
    isShow: Boolean
  }
}
</script>

<style scoped>
.regulation dt{
  font-weight: bold;
  padding-top: 15px;
}
.regulation dd{
  padding-left: 20px;
}
</style>
